import m from "mithril";

import { config } from "../config";
import { t } from "../translate";
import { clearSelection } from "../utils";
import { StoryInfo } from "./Story";

export type PhotoPosition = { lat: number; lon: number };

/** JSON metadata of the photo. */
export interface PhotoInfo {
    /** Main photo title. */
    title: {
        en: string;
        fi: string;
        fr: string;
    };

    /** The photo description is longer than the title. Not used. */
    description?: {
        en: string;
        fi: string;
        fr: string;
    };

    /** False if the photo is not downloadable. True by default. */
    downloadable?: boolean;

    /** False if the photo is not printable on demand. True by default. */
    printable?: boolean;

    /** The story folder. No link to the story if this is missing or empty. */
    story?: string;

    /** Date time when the story has been taken, f.i. "2014-07-06T06:02:58" */
    dateTaken?: string;

    /** Focal length in 35mm equivalent. */
    focalLength35mm?: number;

    /** The exposure time as a fraction, f.i. "1/800" */
    exposureTime?: string;

    /** F-number, f.i. 15.4 */
    fNumber?: number;

    /** ISO number. */
    iso?: number;

    /** GPS coordinates. */
    position?: PhotoPosition;

    /** Each photo folder links to the next one, except the last one. */
    next?: number;

    /** Same logic as "next"... but the other way. */
    prev?: number;

    /**
     * Total number of photo in the related story, if any.
     * Value automatically generated by the Webpack plugin.
     */
    photosInStory?: number;

    /**
     * Photo position in the related story if any, 1 for the oldest photo.
     * Value automatically generated by the Webpack plugin.
     */
    storyPhotoIncrement?: number;

    /** Camera body model name/ID, f.i. "NIKON D7100". */
    body?: string;

    /** Camera lens model name/ID, f.i. "Sigma 10-20mm F3.5 EX DC HSM". */
    lens?: string;

    /** Computational mode for Olympus / OMDS camera. */
    computationalMode?: string;
}

/**
 * Model for managing one photo.
 * @notExported
 */
class Photo {
    /** JSON metadata of the photo. */
    meta: PhotoInfo | null = null;

    /**
     * True if the photo is considered loading.
     * False once the story title is fetched.
     */
    isLoading = true;

    /**
     * True on user action to provide instant feedback even though the photo is
     * really loading after routing and XHR call. Unset synchronised with
     * isLoading.
     */
    isPreloading = true;

    /** Folder containing the photos and JSON file. */
    folderName: number | null = null;

    /** Photo ID. */
    id: number | null = null;

    /**
     * The story title from the story info file.
     * Empty string if the title is loading.
     * Null when there is no story linked to the loaded photo.
     */
    storyTitle: string | null = null;

    /**
     * The language of the story title. Used to compare with
     * the language of the photo when switching the language.
     */
    storyLang: string | null = null;

    /** Duration in microseconds of the loading time of the last load. */
    lastLoadingTime: number | null = null;

    /** Link to the current image. */
    currentImageSrc: string | null = null;

    /** True if fetching the photo metadata returned 404. */
    notFound = false;

    /** Return true if the photo has any metadata available. */
    containsExif(): boolean {
        return this.meta === null
            ? false
            : Boolean(
                  this.meta.focalLength35mm ??
                      this.meta.exposureTime ??
                      this.meta.fNumber ??
                      this.meta.iso ??
                      this.meta.position ??
                      this.meta.body ??
                      this.meta.lens ??
                      this.meta.computationalMode,
              );
    }

    /**
     * True if the "prev" button should be hidden:
     * no photo or currently the first one.
     */
    isFirst(): boolean {
        if (this.meta !== null) {
            return this.meta.next === undefined;
        } else {
            return true;
        }
    }

    /**
     * True if the "next" button should be hidden:
     * no photo or currently the last one.
     * Use Photo.load(config.lastPhotoId) for loading the last photo.
     */
    isLast(): boolean {
        if (this.meta !== null) {
            return this.meta.prev === undefined;
        } else {
            return true;
        }
    }

    /**
     * Load the story metadata. Once fetched, the interface would be updated
     * consequently. That would trigger an additional onupdate event on which
     * the image is considered loaded.
     */
    loadOriginStoryTitle(): void {
        if (!this.meta?.story) {
            this.storyTitle = null;
            this.isLoading = false;
            this.isPreloading = false;
            m.redraw(); // outside the m.request
            return;
        }

        if (this.storyLang !== t.getLang()) {
            this.storyTitle = ""; // reset
        }
        this.storyLang = t.getLang();

        m.request<StoryInfo>({
            method: "GET",
            url: "/content/stories/:folderName/_/i.:lang.json",
            params: {
                folderName: this.meta.story,
                lang: this.storyLang,
            },
        })
            .then((result) => {
                this.storyTitle = result.title ?? null;
                this.isLoading = false;
                this.isPreloading = false;
            })
            .catch(() => {
                this.isLoading = false;
                this.isPreloading = false;
            });
    }

    /**
     * Load a photo at a specific position. The image size depends on the screen
     * size (in pixels). A large photo is loaded if the window height is above
     * 780px. A medium-size photo is loaded otherwise. The loading time is
     * recorded. If it is shorter than 1400 ms and if the photo is large, then
     * the next large photo will be high-def.
     *
     * The photo is in the WebP format, converted from the TIF/JPG format.
     * The WebP converter is libwebp-1. The WebP configuration is:
     * `-preset photo -mt -m 6 -q {quality} -af -resize 0 {height} | {width} 0`
     * with `{quality}` = 90 for `f.webp` (thumbnail of fixed size, 300x200)
     * and `t.webp` (thumbnail) and `m.webp` (medium-size) and `s.*.webp` (small
     * screen), 86 for `l.webp` (large), 98 for `l.hd.webp` (large high-def),
     * and `{height}` = 200 for `t.webp` and `f.webp`, 760 for `m.webp`,
     * 1030 for `l(.hd).webp`, 375 for `s.l.webp` (small for landscape screen),
     * and `{width}` = 300 for `f.webp`, 375 for `s.p.webp` (small for portrait
     * screen).
     *
     * Statistics for the 300 photos:
     *
     * * Thumbnail: 5.4 MiB, 19 KiB per photo,
     * * Thumbnail of fixed size, 300x200px: 5.5 MiB, 19 KiB per photo,
     * * Small for portrait screen: 8.8 MiB, 30 KiB per photo,
     * * Small for landscape screen: 16 MiB, 52 KiB per photo,
     * * Medium: 53 MiB, 179 KiB per photo,
     * * Large: 69 MiB, 236 KiB per photo,
     * * Large high-def: 162 MiB, 551 KiB per photo.
     *
     * Howto: `find public/content/photos/ -iname 'l.hd.webp' -print0 | du
     * --files0-from - -c -h | sort -h`
     */
    load(id: number): Promise<void> {
        this.partialResetState();
        return m
            .request<PhotoInfo>({
                method: "GET",
                url: "/content/photos/:folderName/_/i.json",
                params: { folderName: id },
            })
            .then((result) => {
                const nextImageSrc = this.getImageSrc(id);
                const image = new Image();
                const startTime = performance.now();
                image.onload = () => {
                    this.currentImageSrc = nextImageSrc;
                    this.lastLoadingTime = performance.now() - startTime;
                    // only update the interface when the photo has changed
                    this.meta = result;
                    this.folderName = id;
                    this.id = id;

                    // clicking fast may select the icon, deselect it
                    clearSelection();

                    this.loadOriginStoryTitle();
                };
                image.src = nextImageSrc;
            })
            .catch((error: Error & { code: number }) => {
                if (error.code === 404) {
                    this.notFound = true;
                } else {
                    throw error;
                }
            });
    }

    /** Return the screen/network-optimized source link of the photo. */
    protected getImageSrc(id: number): string {
        let filename = "m";
        if (window.innerWidth <= 375) {
            filename = "s.p";
        } else if (window.innerHeight <= 375) {
            filename = "s.l";
        } else if (window.innerHeight > 780) {
            filename = "l";
            if (this.lastLoadingTime && this.lastLoadingTime < 1400) {
                filename += ".hd";
            }
        }
        return `/content/photos/${id}/${filename}.webp`;
    }

    /**
     * Load the previous photo (selected based on the metadata)
     * or the first one if the previous one is not linked.
     */
    loadPrev(): void {
        this.isPreloading = true;
        const prevFolderId = !this.meta?.next
            ? config.firstPhotoId
            : this.meta.next;

        m.route.set("/:lang/photo/:title", {
            lang: t.getLang(),
            title: prevFolderId,
        });
    }

    /**
     * Load the next photo (selected based on the metadata)
     * or the first one if the next one is not linked.
     */
    loadNext(replaceHistory = false): void {
        this.isPreloading = true;
        const nextFolderId = !this.meta?.prev
            ? config.firstPhotoId
            : this.meta.prev;

        m.route.set(
            "/:lang/photo/:title",
            {
                lang: t.getLang(),
                title: nextFolderId,
            },
            {
                replace: replaceHistory,
            },
        );
    }

    /** Path to the story of the loaded photo or null if not available. */
    getStoryPath(): string | null {
        if (!this.meta?.story) {
            return null;
        }
        return m.buildPathname("/:lang/story/:folderName", {
            lang: t.getLang(),
            folderName: this.meta.story,
            /* key to go back to the photo once in the story page */
            from_photo: this.id,
        });
    }

    /** Reset to defaults / initial state, enough for photo switching. */
    partialResetState(): void {
        this.isLoading = true;
        this.isPreloading = true;
        this.notFound = false;
    }

    /** Reset to invalidate data before updating the view. */
    fullResetState() {
        this.partialResetState();
        this.meta = null;
        this.currentImageSrc = null;
    }
}

/** This is a shared instance. */
export const photo = new Photo();
